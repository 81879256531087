.Navbar{
    position: absolute;
    left:300px;
    width: 73%;
    margin-top:2%;
    .Title{
        font-size: xx-large;
    font-weight: 800;
    color:$navBarMainColor;
    }
    .SeConnecter{
        padding-left: 16%;
    }
    button{
        border: none;
        background: $MainColor4;
        color: #fff;
        padding: 2% 3%;
    }
}