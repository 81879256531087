@font-face {
  font-family: Quickens;
  src: url("../assets/fonts/Quickens.otf") format("woff");
}

.Sidebar {
  position: fixed !important;
  background-color: #fff;
  width: 270px;
  -webkit-box-shadow: 0 0 11px -5px #000;
          box-shadow: 0 0 11px -5px #000;
  text-align: center;
  padding: 30px 20px;
  height: 100vh;
  position: relative;
  z-index: 100;
  min-height: 100vh;
  height: auto;
}

.Sidebar .divLogo {
  width: 70%;
  margin-left: 15%;
}

.Sidebar img {
  width: 100%;
}

.Sidebar .SideBareMenu {
  position: relative;
  top: 62px;
}

.Sidebar .SideBareMenu .Link {
  margin: 10%;
}

.Sidebar .SideBareMenu .linkMenu {
  width: 128%;
  background: transparent;
  color: #D84097;
  border: none;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  border-radius: 5px;
  padding: 2%;
  padding-right: 70px;
  text-transform: uppercase;
  margin-left: -32px;
}

.Sidebar .SideBareMenu .linkMenu:hover {
  background: linear-gradient(87deg, #FBE14A, #D84097) !important;
}

.Sidebar .SideBareMenu .linkMenu:hover span {
  color: white;
}

.Sidebar .SideBareMenu .linkMenu span {
  text-transform: uppercase;
}

.Sidebar .SideBareMenu .classActive {
  width: 128%;
  background: linear-gradient(87deg, #FBE14A, #D84097) !important;
  color: #fff;
  border: none;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  border-radius: 5px;
  padding: 2%;
  padding-right: 70px;
  text-transform: uppercase;
  margin-left: -32px;
}

.Navbar {
  position: absolute;
  left: 300px;
  width: 73%;
  margin-top: 2%;
}

.Navbar .Title {
  font-size: xx-large;
  font-weight: 800;
  color: #FBE14A;
}

.Navbar .SeConnecter {
  padding-left: 16%;
}

.Navbar button {
  border: none;
  background: #D84097;
  color: #fff;
  padding: 2% 3%;
}

.DuplicateClass {
  position: absolute;
  margin-left: 300px;
  top: 120px;
  background-color: #fff;
  -webkit-box-shadow: 0 0 11px -5px #000;
          box-shadow: 0 0 11px -5px #000;
  width: 73%;
  padding: 5%;
}

.DuplicateClass .add {
  margin-top: 10px;
}

.DuplicateClass .add span {
  background-color: #fff;
  color: #000;
  margin-right: 10px;
  padding: 2px 6px;
  border-radius: 10%;
}

.DuplicateClass .imageSlider {
  margin-right: 10px;
  border-radius: 10px;
  width: 240px;
}

.DuplicateClass .rowFlex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

.MagCart {
  width: 30%;
}

.MagCart p {
  width: 100%;
  word-break: break-word;
}

.Slider .liPacksBilletterie {
  font-size: large;
  font-weight: 700;
}

.descriptionList {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.descriptionList svg {
  width: 20px;
  margin: 0% 5%;
  cursor: pointer;
}

.Login {
  width: 100%;
  height: 100vh;
  background-color: #D84097;
}

.Login .divImage {
  margin-bottom: 5%;
  padding-top: 5%;
}

.Login .divLogin {
  background-color: #FBE14A;
  width: 40%;
  margin-left: 30%;
  padding: 5%;
}

.Login input {
  border: none !important;
  margin: 2% !important;
  width: 60% !important;
}

.Login .btn {
  background-color: #06A5DF;
  color: #fff;
  border-radius: 0;
  margin-top: 10px;
}
