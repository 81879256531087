.Slider {
    .liPacksBilletterie {
        font-size: large;
    font-weight: 700;

    }
}

.descriptionList {
    display: flex;
    svg {
        width: 20px;
        margin: 0% 5%;
        cursor: pointer;
    }
}