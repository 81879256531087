.Sidebar {
    position: fixed !important;
    background-color: #fff;
    width: 270px;
    box-shadow: 0 0 11px -5px #000;
    text-align: center;
    padding: 30px 20px;
    height: 100vh;
    position: relative;
    z-index: 100;
    min-height: 100vh;
    height: auto;
    .divLogo{
        width: 70%;
        margin-left: 15%;
    }
    img{
        width: 100%;
    }
    .SideBareMenu {
        position: relative;
        top: 62px;
        .Link{
            margin:10%
        }
        .linkMenu{
            width:128%;
            background: transparent;
            color:$MainColor4;
             border:none;
             transition: all 0.2s ease;
             border-radius: 5px;
             padding: 2%;
            padding-right: 70px;
            text-transform:uppercase;
            margin-left: -32px;
            &:hover{
                background: linear-gradient(87deg,$navBarMainColor,$MainColor4)!important;
                span{
                    color: white;
                }
            }
            span{
                // margin-left:15px;
                text-transform:uppercase ;
            }
            
        }
        .classActive{
            width:128%;
            background: linear-gradient(87deg,$navBarMainColor,$MainColor4)!important;
            color:#fff;
             border:none;
             transition: all 0.2s ease;
             border-radius: 5px;
             padding: 2%;
            padding-right: 70px;
            text-transform:uppercase;
            margin-left: -32px;
        }
       
        
    }
    
}