.Login{
    width: 100%;
    height: 100vh;
    background-color: $MainColor4;
    .divImage{
        margin-bottom:5%;
        padding-top:5%;
    } 
    .divLogin{
        background-color:$navBarMainColor;
        width: 40%;
        margin-left:30%;
        padding: 5%;
    }
     input{
        border: none !important;
        margin: 2% !important;
        width: 60% !important;
    }
  
    .btn{
        background-color:$MainColor3;
        color: #fff;
        border-radius:0;
        margin-top:10px ;
    }
}