.DuplicateClass {
    position: absolute;
    margin-left: 300px;
    top: 120px ;
    background-color: #fff;
    box-shadow: 0 0 11px -5px #000;
    width: 73%;
    padding: 5%;
    
    img {
        // width: 26%;
    }

    .add {
        margin-top: 10px;
        span {
            background-color: #fff;
            color: #000;
            margin-right: 10px;
            padding: 2px 6px;
            border-radius: 10%;
        }
    }
    .imageSlider{
        margin-right: 10px;
        border-radius :10px;
        width: 240px;
    }
    .rowFlex{
        display:flex;
        flex-wrap: wrap;
    }
}