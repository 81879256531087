$navBarMainColor: #FBE14A;
$MainColor1: #F39831;
$MainColor2: #3D4692;
$MainColor3: #06A5DF;
$MainColor4: #D84097;
$MainColor5: #07B4F3;

$main_font: 'Quickens', serif;

$X_Small: 576px;
$Small: 576px;
$Medium: 768px;
$Large: 992px;
$Extra_large: 1200px;
$Extra_extra_large: 1400px;